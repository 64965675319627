import React from 'react'
import { useLocation } from 'react-router-dom'
import { SingleChallenge } from '../components'
import {urlFor} from '../client'

const FullChallenge = () => {
    const location = useLocation()
    const state = location.state
  return (
    <div>
        <SingleChallenge title={state.title} desc={state.desc} brief={state.brief} difficulty={state.difficulty} image={String(urlFor(state.image))} assets={state.assets} paid={state.paid} paidAssets={state.paidAssets} stripeLink={state.stripeLink}  />
    </div>
  )
}

export default FullChallenge