import React from 'react'
import {Challenges} from "../components"
import { Routes, Route } from 'react-router-dom'
import FullChallenge from './FullChallenge'

const AllChallenges = () => {
  return (
    <div>
        <Routes>
            <Route path='/fullChallenge/*' element={<FullChallenge />}/>
      </Routes>
        <Challenges />
    </div>
  )
}

export default AllChallenges