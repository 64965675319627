import React, {useState, useEffect} from 'react'
import './Challenges.scss'
import {urlFor, client} from '../../client'
import ChallengeCard from '../../components/ChallengeCard/ChallengeCard'


const Challenges = () => {
  const [challengeList, setChallengeList] = useState([])
  
  useEffect(() => {
    const query = '*[_type == "challenges"] | order(_createdAt desc)'
    client.fetch(query)
    .then((data) => setChallengeList(data))
  }, [])
  return (
    <>
      <section className='challenges'>
        <div className='shape'></div>
        <h2>Featured Challenges</h2>
        <div className='challengesList'>
          {challengeList.map((challenge, index) =>(
            <ChallengeCard key={index} title={challenge.title} desc={challenge.description} brief={challenge.brief} difficulty={challenge.difficulty} image={String(urlFor(challenge.image))} assets={challenge.assets} paid={challenge.paid} paidAssets={challenge.paidAssets} stripeLink={challenge.stripeLink} />
          ))}
        </div>
      </section>
    </>
  )
}

export default Challenges