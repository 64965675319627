import React, {useState} from 'react'
import './Navbar.scss'
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import logo from '../../images/GDCLogo.png'

const Menu = () => {
  return (
    <>
      <p><Link to="/">Home</Link></p>
      <p><Link to="/allChallenges">Challenges</Link></p>
      <p><Link to="/#about">About</Link></p>
      {/* <p><a href='#features'>Case Studies</a></p> */}
      {/* <p><a href='#blog'>Library</a></p> */}
    </>
  )
}

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  return (
    <div className='gpt3__navbar gradient_bg'>
      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
          {/* <img src={logo} alt='logo' /> */}
          <Link to="/"><img src={logo} alt='logo'/></Link>
        </div>
        <div className='gpt3__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='gpt3__navbar-sign'>
        {/* <p>Sign In</p> */}
        <a href='https://discord.gg/Z8pFDZ84qX' target="_blank" rel="noopener noreferrer"><button type='button'>Community</button></a>
      </div>
      <div className='gpt3__navbar-menu'>
        {toggleMenu 
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} /> 
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} /> 
        }
        {toggleMenu && (
          <div className='gpt3__navbar-menu_container scale-up-center'>
            <div className='gpt3__navbar-menu_container-links'>
              <Menu />
              <div className='gpt3__navbar-menu_container-links-sign'>
                {/* <p>Sign In</p> */}
                <a href='https://discord.gg/Z8pFDZ84qX' target="_blank" rel="noopener noreferrer"><button type='button'>Community</button></a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar