import React, {useState, useEffect} from 'react'
import './SingleChallenge.scss'
import {PortableText} from '@portabletext/react'
import {client} from '../../client'

const SingleChallenge = ({title, desc, difficulty, image, paid, brief, assets, paidAssets, stripeLink}) => {

  const [fileURL, setFileURL] = useState("")

  useEffect(() => {
    // const query = '*[_type == "challenges"] | order(_createdAt desc)'
    client.fetch(`*[_type == "challenges" && title == "${title}"]{
      "assetURL": assets.asset->url
    }`).then((data) => {
      setFileURL(data[0].assetURL)
    })
    window.scrollTo(0, 0)
  }, [title])

  const handleStripe = () => {
    fetch("http://localhost:3000/create-checkout-session",{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        items:[
          {id: title, quantity: 1}
        ],
      }),
    }).then(res =>{
      if(res.ok) return res.json()
      return res.json().then(json => Promise.reject(json))
    }).then(({url}) => {
      window.location = url
    }).catch(e => {
      console.error(e.error)
    })
  }

  return (
    <div className='singleChallenge'>
      <div className='preview'>
        <img src={image} alt={title} />
        <div className='previewText'>
          <h2>{title}</h2>
          <PortableText value={desc} />
          <div className='levelInfoWrapper'>
            <p className={`${paid ? "premium" : "free"}`}>{paid ? "Premium" : "Free"}</p>
            <p className={`difficulty ${difficulty}`}>{difficulty}</p>
          </div>
          
        </div>
      </div>
      <div className='details'>
        <div className='assets'>
          <h3>📦 Free assets:</h3>
          <ul>
            <li>All art files</li>
            <li>README.md file for design parameters</li>
            <li>Any tertiary scripts for game function</li>
          </ul>
          <a href={fileURL} download target="_blank" rel="noreferrer">Download Free Assets</a>
          <h3>🎮 Paid Assets:</h3>
          <ul>
            <li>All of the free assets</li>
            <li>Complete game files with all other systems working</li>
            <li>Download link will be provided after purchase</li>
          </ul>
          {/* <a className='premiumAssets' onClick={handleStripe}>Purchase game files</a> */}
          <a className='premiumAssets' href={stripeLink}>Purchase game files</a>
        </div>
        <div className='brief'>
          <h3>📜 Project Brief:</h3>
          <PortableText value={brief} />
        </div>
      </div>
    </div>
  )
}

export default SingleChallenge