import React from 'react'
import '../../src/App.scss';
import {Hero, About, Challenges, SignUp} from '../components';
import { Routes, Route } from 'react-router-dom'
import AllChallenges from './AllChallenges';
import FullChallenge from './FullChallenge'

const Home = () => {
  return (
    <div className="App">
      <Routes>
        <Route path='/allChallenges/*' element={<AllChallenges />}/>
        <Route path='/fullChallenge/*' element={<FullChallenge />}/>
        <Route path='/fullChallenge/:id' element={<FullChallenge />}/>
      </Routes>
      <Hero />
      <About id="about" />
      <Challenges />
      <SignUp />
      {/* <Footer /> */}
    </div>
  )
}

export default Home