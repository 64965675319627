import React from 'react'
import './Signup.scss'
import discord from '../../images/discord-logo.png'

const Signup = () => {
  return (
    <section id='signup' className="signup">
      <h2>Join the Discord Community</h2>
      <p>Game developers of all levels and experience helping each other build better games. Talk about your solutions, swap stories and implementations, even partner with others to start the next indie studio to take over the world. We <em>make</em> games, we <em>play</em> games, we <em>love</em> games.</p>
      <a href='https://discord.gg/Z8pFDZ84qX' target="_blank" rel="noopener noreferrer"><button type='button'>Community</button></a>
      <img src={discord} alt="discord logo" />
    </section>
  )
}

export default Signup