import React from 'react'
import './ChallengeCard.scss'
import {PortableText} from '@portabletext/react'
import { Link } from 'react-router-dom'

const ChallengeCard = ({title, desc, difficulty, image, paid, brief, assets, paidAssets, stripeLink}) => {
  const data = {
    title: title,
    desc: desc,
    difficulty: difficulty,
    image: image,
    paid: paid,
    brief: brief,
    assets: assets,
    paidAssets: paidAssets,
    stripeLink: stripeLink
  }
  return (
    <>
      {/* <Link to={`/fullChallenge/${title}`} className='challengeCard' state={title} data-challenge={title}> */}
      <Link to={`/allChallenges/fullChallenge/${title}`} className='challengeCard' state={data} data-challenge={title}>
          <img src={image} alt={title}/>
          <h3>{title}</h3>
          <PortableText value={desc} />
          <div className='challengeHighlights'>
            <p className={`${paid ? "premium" : "free"}`}>{paid ? "Premium" : "Free"}</p>
            <p className={`difficulty ${difficulty}`}>{difficulty}</p>
          </div>
      </Link>
    </>
    
  )
}

export default ChallengeCard

//, image, paid, brief, assets, paidAssets