import React from 'react'
import './About.scss'
import {RiCheckboxCircleLine, RiCodeSSlashLine, RiDownloadCloud2Line, RiUploadCloud2Line} from 'react-icons/ri'
import { IconContext } from 'react-icons/lib'

const About = () => {
  return (
    <IconContext.Provider value={{ color: '#81AFDD', size: '4em'}}>
    <section id='about' className='about'>
      <h2>How challenges work</h2>
      <div className='aboutContainer'>
        <div className='aboutCard'>
          <RiCheckboxCircleLine className='icon'/>
          <h3>Pick a challenge</h3>
          <p>Take a look through the challenge tickets. Choose one that would be a good fit for you at your current level.</p>
        </div>
        <div className='aboutCard'>
          <RiDownloadCloud2Line />
          <h3>Download the assets</h3>
          <p>Start the challenge by downloading the provided assets. Assets often include all game objects, art, and designs. Pro members can get full game files.</p>
        </div>
        <div className='aboutCard'>
          <RiCodeSSlashLine />
          <h3>Code the feature</h3>
          <p>Choose the game engine or framework you would like to work on. How you implement the solution is up to you. We are all about functionality and learning.</p>
        </div>
        <div className='aboutCard'>
          <RiUploadCloud2Line />
          <h3>Submit your solution</h3>
          <p>Post your game to the <a href='#signup'>Discord</a> community and receive feedback from fellow game developers. Stick around and encourage others on their journey.</p>
        </div>
      </div>
    </section>
    </IconContext.Provider>
  )
}

export default About