import './App.scss';
import {Navbar} from './components';
import { Routes, Route } from 'react-router-dom'
import AllChallenges from './pages/AllChallenges';
import Home from './pages/Home';
import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('G-60S4V5C4KL');
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <>
    <div className="App">
      <Navbar />
      <Routes>
        <Route path='/allChallenges/*' element={<AllChallenges />}/>
        <Route path='/*' element={<Home />}/>
      </Routes>
      {/* <Hero />
      <About />
      <Challenges />
      <SignUp /> */}
      {/* <Footer /> */}
    </div>
    
    </>
  );
}

export default App;
