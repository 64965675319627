import React, {useState, useEffect} from 'react'
import './Hero.scss'
import hero1 from '../../images/heroImage1.jpeg'

const Hero = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1200;
          if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
  }, [isMobile]);

  return (
    <main className='hero'>
      <div className={`hero-left ${isMobile ? "" : "blob"}`}>
        <h1>Level-up your game development coding skills by building components from real games.</h1>
        <p>Solve real-world game development challenges and get experience working in a professional environment. Join the community building games, reviewing code, and inspiring each other.</p>
        <a href='https://discord.gg/Z8pFDZ84qX' target="_blank" rel="noopener noreferrer"><button type='button'>Community</button></a>
      </div>
      <div className='hero-right'>
        <img src={hero1} alt="" />
      </div>
    </main>
  )
}

export default Hero